import SecondmentTravelplanService from "@/core/services/secondment/secondment-travelplan.service";
import _ from "lodash";
import api from "../api.service";

class SecondmentService {
  get PATH() {
    return "secondment";
  }

  async get({
    perPage,
    page,
    sortBy = null,
    sortDesc = null,
    search = null,
    date_end_gt = null,
    date_end_lt = null,
    date_start_gt = null,
    date_start_lt = null,
    secondmentStatus = null,
    omit = null,
    fields = null,
    expand = null,
    adminView = false,
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (secondmentStatus) {
      params.status = secondmentStatus;
    }

    if (date_end_gt) {
      params.date_end__gt = date_end_gt;
    }

    if (date_end_lt) {
      params.date_end__lt = date_end_lt;
    }

    if (date_start_gt) {
      params.date_start__gt = date_start_gt;
    }

    if (date_start_lt) {
      params.date_start__lt = date_start_lt;
    }

    if (omit && omit.length) {
      params.omit = omit;
    }

    if (fields && fields.length) {
      params.fields = fields;
    }

    if (expand && expand.length) {
      params.expand = expand;
    }

    if (adminView) {
      params.admin_view = true;
    }

    return await api.get(`${this.PATH}/`, {
      params,
    });
  }

  async getOne({
    id,
    expand,
    omit,
    fields,
    adminView
  }) {
    const params = {};
    if (expand && expand.length) {
      params.expand = expand;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (adminView) {
      params.admin_view = adminView;
    }
    return await api.get(`${this.PATH}/${id}/`, {
      params,
    });
  }

  async create(body, options = null) {
    body.employees = body.employees.map((el) => {
      const newEl = _.cloneDeep(el);
      delete newEl.places;
      return newEl;
    });

    const r = await api.post(`${this.PATH}/`, body);

    if (options == options.returnConvertedTravelPlans) {
      const convertedPlans = await SecondmentTravelplanService.convertTravelPlansResponse(
        r.data.travel_plans,
        r.data.employees,
      );

      r.data.travel_plans = convertedPlans;
    }

    return r.data;
  }

  async update(body, id /*options = null*/ ) {
    if (body.employees) {
      body.employees = body.employees.map((el) => {
        const newEl = _.cloneDeep(el);
        delete newEl.places;
        return newEl;
      });
    }

    const r = await api.patch(`${this.PATH}/${id}/`, body);
    r.data.travel_plans = r.data.travel_plans.map((tp) => {
      tp.travel_days = tp.travel_days.map((td) => {
        td.places = td.places.map((p) => {
          p.place = p.place.id;
          return p;
        });
        return td;
      });
      return tp;
    });

    return r.data;
  }

  async updateStatus(status, id, adminView) {
    const r = await api.patch(`${this.PATH}/${id}/?admin_view=${adminView}`,
      status,
    );
    return r.data;
  }

  async delete(id) {
    await api.delete(`${this.PATH}/${id}/`);
  }

  async getStatuses() {
    const res = await api.options(`${this.PATH}/`);
    return res.data.actions.POST.status.choices;
  }

  async getDocuments({
    id,
    persona_id,
    perPage,
    page,
    sortBy,
    search,
    sortDesc,
    fields = null,
    expand = null,
    omit = null,
    expiring=false
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      expiring,
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (persona_id) {
      params['persona'] = persona_id
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const res = await api.get(`${this.PATH}/${id}/get_secondment_documents/`, { params });
    return res.data;
  }

  async checkDateStart(country, date_start, date_end) {
    let datas = {
      country
    };

    if (date_start) {
      datas = {
        country,
        date_start
      };
    }
    if (date_end) {
      datas.date_end = date_end;
    }

    return await api.post(`${this.PATH}/check_date_start/`, datas);
  }

  async addConvertedPlans(options, response) {
    if (options && options.returnConvertedTravelPlans) {
      const convertedPlans = await SecondmentTravelplanService.convertTravelPlansResponse(
        response.data.travel_plans,
        response.data.employees,
      );
      response.data.travel_plans = convertedPlans;
    }

    return response.data
  }
}

export default new SecondmentService();