import AddressService from "@/core/services/address.service";

export default {
  get PATH() {
    return "secondment-travelplan";
  },

  async makeTravelPlansRequestBody(travelPlans) {
    const newPlans = travelPlans.map((plan) => {
      const days = plan?.days || plan?.travel_days;

      return {
        id: plan.id,
        travel_days: days.map((day) => {
          // eslint-disable-next-line no-unused-vars
          const { housing, housing_order, date, travel_date, order, ...newDay } = day;
          return {
            ...newDay,
            travel_date: travel_date || date,
          };
        }),
        employees: plan.employees.map((employee) => (typeof employee == "object" ? employee.id : employee)).filter(id => id != null),
      };
    });

    const addresses = {};
    let out = [];
    for (let plan of newPlans) {
      let newPlan = {
        travel_days: [],
        employees: plan.employees,
      };

      if (plan?.id) {
        newPlan.id = plan.id;
      }

      for (let day of plan.travel_days) {
        day.places = day.places.filter((el) => el);

        day.places = await Promise.all(
          day.places
            .filter((el) => el?.place_kind)
            .map(async (key, idx) => {
              const googlePlace = key.place;

              let placeId = googlePlace?.place_id;
              if (googlePlace && typeof googlePlace == "object") {
                if (addresses[placeId] == undefined) {
                  if (addresses[googlePlace.id]) {
                    placeId = googlePlace.id;
                  } else {
                    const [apiAddr] = await AddressService.getOrCreateAddress([{
                        address: googlePlace,
                        manual_address: false,
                    }]);
                    addresses[placeId] = apiAddr.id;
                  }
                }
                key.place = addresses[placeId];
                key.order = key?.order ?? idx;
              }

              return key;
            }),
        );

        newPlan.travel_days.push(day);
      }

      out.push(newPlan);
    }
    return out;
  },

  async convertTravelPlanResponse(apiTravelPlan, secondmentEmployees) {
    const convertTravelDays = async (days) => {
      const outDays = [];
      if (!days) return outDays;

      for (const value of days.values()) {
        outDays.push({
          id: value.id,
          places: await Promise.all(value.places.map(async (el) => AddressService.getGooglePlaceFromApi(el))),
          date: value.travel_date,
        });
      }

      return outDays;
    };

    const out = {
      ...apiTravelPlan,
      days: await convertTravelDays(apiTravelPlan.travel_days),
      employees: apiTravelPlan.employees.map((secEmpId) => secondmentEmployees.find((secEmp) => secEmp.id == secEmpId)),
    };

    return out;
  },

  async convertTravelPlansResponse(apiTravelPlans, secondmentEmployees) {
    const out = [];

    for (const travelPlan of apiTravelPlans) {
      const converted = await this.convertTravelPlanResponse(travelPlan, secondmentEmployees);
      out.push(converted);
    }

    return out;
  },
};
